import * as React from "react"
import { Link } from "gatsby"
import { globalHistory } from '@reach/router'
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
// import { Section } from "@atoms/Section"
import Error404Svg from "@images/Error__img__404.svg"
import * as css from "@css/layouts/l-error.module.styl"

function Error404Page(){
    const { location } = globalHistory

    if (typeof window !== 'undefined' && !location.pathname.match(/404/g)) {
        window.location = '/404.html';
    }

    return(
        <div className={css.lError}>
            <SEO subtitle="404 Not Found." />
            <div className={css.lError__inner}>
                {/* <div className={lError__image}>
                    <Error404Svg />
                </div> */}
                <h1 className={css.lError__title}>404 NOT FOUND</h1>
                <p className={css.lError__text}>無紙</p>
                <div className={css.lError__textSmall}>
                    <a href="/">GO TO TOP</a>
                </div>
            </div>
        </div>
    )
}

export default Error404Page
